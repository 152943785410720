<template>
  <div>
    <div class="indexUser">
      <div>
        <!-- header  点击 -->
        <headers :userList="userList"></headers>
      </div>
      <div class="bg1 userBox">
		  <div class="box">
			  <div class="top">
				<div class="head" >
					  <img v-if="userList.avatar==''" src="../../../assets/image/Furtherconsultation_Defaulthead@2x.png" alt="">
					  <img v-else :src="userList.avatar" alt="">
				</div>
				  <div class="infor">
					  <p class="name">{{userList.user_nickname}}</p>
					  <p>{{levelinfor.title}}会员
					  <img src="../../../assets/image/Listofpeople_expert_icon@2x.png" alt="">
					  <span class="zk">会员折扣:</span><span class="zkn">{{levelinfor.discount==="无"?"无折扣":levelinfor.discount +"折"}}</span></p>
					  <p>距离下一等级还差{{levelinfor.differ_exp}}经验值，每购物1元加1经验值</p>
				  </div>
				  <div class='center'>
					  <p>总经验值</p>
					  <p class="dc">{{levelinfor.user_level_exp}}</p>
				  </div>
				  <div class="righttext">
					  <p draggable="true">昨日经验值</p>
					  <p  class="dc">+{{levelinfor.yesterday_exp}}</p>
				  </div>
			  </div>
			  <div class="dengji">
				  <h4>会员等级</h4>
				  <div v-for="(item,index) in levelinfor.level_list ">
					<span>  {{item.title}}</span>
					<span>{{item.exp}}</span>
					<span>{{item.discount}}折</span>
				  </div>
			  </div>
		  </div>
      </div>
      <div>
        <publicBottom></publicBottom>
      </div>
    </div>
  </div>
</template>
<script>
import headers from "./header"; // 头部 TOP 和header
import publicBottom from "@/components/public/public_bottom"; //底部
import { usergetinfo } from "@/request/user";

import QRCode from "qrcodejs2";
export default {
  components: {
    headers,
    publicBottom
  },
  data() {
    return {
      userList: [] ,// 个人信息
	  levelinfor:"",
    };
  },
  created() {
    let datas = localStorage.getItem("lzyyToken");
    let Athis = this;
    if (datas != null) {
      this.$tokens = datas;
    } else {
      this.$message.error("登录错误，请重新登录");
      setTimeout(function() {
        Athis.$router.replace({ path: "/land" });
      }, 1000);
    }

    this.init();
	this.getlevel();
  },
  methods: {
	  getlevel(){
		  
		this.$axios.post("/api/user/user_level",{
			token:this.$token
		}).then(res=>{
			if(res.data.code==1){
				this.levelinfor=res.data.data;
				console.log(this.levelinfor);
			}
		})
	  },
    init() {
      //   个人信息
      usergetinfo({
        token: this.$tokens
      }).then(res => {
        this.userList = res.data;
        console.log(this.userList)
        this.createCode();
      });
    },
    createCode() {
      var qrcode4 = new QRCode(document.getElementById("qrcode4"), {
        text: this.userList.member_card,
        width: 450,
        height: 450
      });
      window.JsBarcode("#barcode", `${this.userList.member_card}`, {
        width: 3.7,
        height: 200
      });
    }
  }
};
</script>

<style lang="less" scoped>
	.dengji{
		h4{
			text-align: center;
			margin: 20px;
		}
		div{
			display: flex;
			justify-content: space-between;
			span{
				width: 300px;
				text-align: center;
				padding: 20px;
				border-bottom: 1px solid #EAEAEA;
			}
		}
	}
	.box{
		width: 800px;
		height: 400px;
		padding: 20px;
		background-color: #fff;
		margin: 0 auto;
		p{
			margin: 5px 0;
		}
		.dc{
			color:#64efcd;
			margin-top: 20px;
		}
		.top{
			display: flex;
			height: 100px;
			.head{
				flex: 2;
				padding: 0 10px;
				font-size: 18px;
				img{
					display: block;
					height: 100px;
					margin: 0 auto;
				}
			}
			.infor{
				flex: 4;
				vertical-align: middle;
				.name{
					font-size: 18px;
				}
				p:last-of-type{
					font-size: 14px;
					color:#4D4D4D;
				}
				img{
					width: 16px;
				}
				.zk{
					margin-left: 20px;
				}
				.zkn{
					color:#64efcd;
				}
			}
			.center{
				flex: 2;
				text-align: center;
				border-left: 2px solid #F0F0F0;
				border-right: 2px solid #f0f0f0;
			}
			.righttext{
				flex: 2;
				text-align: center;
			}
			
		}
	}
</style>